import React, { useState } from "react";
import avatar from "../../assets/images/avatar.svg";
import "../../assets/css/Contact.css";
import { IoMdArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import ThankYouPopup from "../ThankYouPopup";

const Contact = () => {
  const [emptyFieldError, setEmptyFieldError] = useState(false);
  const [showPopup, setShowPopup] = useState(false)
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    message: "",
  });
  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUserData({ ...userData, [name]: value });
  };

  // connect with firebase
  const submitData = async (event) => {
    event.preventDefault();
    const { name, email, message } = userData;

    if (name && email && message) {
      const res = await fetch(
        "https://portfolio-contact-page-95156-default-rtdb.firebaseio.com/contactData.json",
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            message,
          }),
        }
      );

      if (res) {
        setUserData({
          name: "",
          email: "",
          message: "",
        });
        setShowPopup(true)
      } else {
        alert("one or more error occured");
      }
    } else {
      setEmptyFieldError(true);

      // Reset the error state after 3 seconds
      setTimeout(() => {
        setEmptyFieldError(false);
      }, 3000);
    }
  };
  return (
    <>
      <div className="page_wrapper">
        <div className="cancel_icon_container">
          <Link to="/">
            <IoMdArrowBack className="cancel_icon" />
            <span>
              <span>Back to Home</span>
            </span>
          </Link>
        </div>
        <div className="avatar_container">
          <img src={avatar} alt="" />
        </div>
        <div className="contact_form_wrapper">
          <h1>
            Thanks for taking the time to react out. <br /> How can i help you
            today ?
          </h1>

          <form method="POST">
            <div className="input_row flex flex-center gap_50">
              <div className="input_item">
                <label htmlFor="Name">
                  Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={userData.name}
                  onChange={postUserData}
                  required
                />
              </div>
              <div className="input_item">
                <label htmlFor="Email">
                  Email<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={userData.email}
                  onChange={postUserData}
                  required
                />
              </div>
            </div>
            <div className="input_row textarea_row">
              <div className="input_item textarea_item">
                <label htmlFor="Message">
                  Message<span style={{ color: "red" }}>*</span>
                </label>
                <textarea
                  rows={5}
                  cols={0}
                  name="message"
                  value={userData.message}
                  onChange={postUserData}
                  required
                ></textarea>
              </div>
            </div>
            {emptyFieldError && (
              <>
                <p className="error">Please fill all the field</p>
              </>
            )}
            <div className="input_row flex justify-center">
              <button type="submit" onClick={submitData}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      {showPopup && (
        <>
          <ThankYouPopup 
            message="Your query has been submitted successfully! We will get back to you soon. Stay tuned!"
            onClose={()=> setShowPopup(false)}
          />
        </>
      )
      }

    </>
  );
};

export default Contact;
