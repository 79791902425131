import React, {useEffect} from 'react';
import Header from './components/Header';
import {Routes, Route} from "react-router-dom";
import Footer from './components/Footer';
import Home from './components/pages/Home';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from './components/pages/Contact';
import Collaborate from './components/pages/Collaborate';
import ScrollToTop from './components/ScrollToTop';
import Project_Discussion from './components/pages/Project_Discussion';

const App = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
      <ScrollToTop />
      <Header />
      <main>
        <Routes>
            <Route path='/' element={<Home />} />
            <Route path="contact" element={<Contact />} />
            <Route path="startup_enquiry" element={<Collaborate />} />
            <Route path="project_discussion" element={<Project_Discussion />} />
        </Routes>
      </main>
      <Footer />
    </>
  )
}

export default App