import React, { useState } from "react";
import "../../assets/css/Contact.css";
import avatar from "../../assets/images/avatar.svg";
import { IoMdArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import ThankYouPopup from "../ThankYouPopup";

const Project_Discussion = () => {
  const [emptyFieldError, setEmptyFieldError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    type: "",
    budget: "",
    details: "",
  });

  let name, value;
  const postUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUserData({ ...userData, [name]: value });
  };

  // Handle form submission
  const submitData = async (event) => {
    event.preventDefault();
    const { name, email, type, budget, details } = userData;

    if (name && email && type && budget && details) {
      const res = await fetch(
        "https://portfolio-contact-page-95156-default-rtdb.firebaseio.com/projectData.json",
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            type,
            budget,
            details,
          }),
        }
      );

      if (res) {
        setUserData({
          name: "",
          email: "",
          type: "",
          budget: "",
          details: "",
        });
        setShowPopup(true);
      } else {
        alert("An error occurred while submitting data.");
      }
    } else {
      setEmptyFieldError(true);

      // Reset the error state after 3 seconds
      setTimeout(() => {
        setEmptyFieldError(false);
      }, 3000);
    }
  };

  return (
    <>
      <div className="page_wrapper">
        <div className="cancel_icon_container">
          <Link to="/">
            <IoMdArrowBack className="cancel_icon" />
            <span>
              <span>Back to Home</span>
            </span>
          </Link>
        </div>
        <div className="avatar_container">
          <img src={avatar} alt="" />
        </div>
        <div className="contact_form_wrapper">
          <h1>
            Want to discuss a startup collaboration? <br /> I'm most definitely
            game.
          </h1>

          <form method="POST">
            <div className="input_row flex flex-center gap_50">
              <div className="input_item">
                <label htmlFor="Name">Name<span style={{ color: "red" }}>*</span></label>
                <input
                  type="text"
                  name="name"
                  value={userData.name}
                  onChange={postUserData}
                  required
                />
              </div>
              <div className="input_item">
                <label htmlFor="Email">Email<span style={{ color: "red" }}>*</span></label>
                <input
                  type="email"
                  name="email"
                  value={userData.email}
                  onChange={postUserData}
                  required
                />
              </div>
            </div>
            <div className="input_row flex flex-center gap_50">
            <div className="input_item">
                <label htmlFor="Interest">Type of Project<span style={{ color: "red" }}>*</span></label>
                <select
                  name="type"
                  id="type"
                  value={userData.type}
                  onChange={postUserData}
                  required
                >
                  <option value="">Select<span style={{ color: "red" }}>*</span></option>
                  <option value="webapp">Web App</option>
                  <option value="mobileapp">Mobile App</option>
                  <option value="cybersecurity">Cyber Security</option>
                </select>
              </div>
              <div className="input_item">
                <label htmlFor="Interest">Budget<span style={{ color: "red" }}>*</span></label>
                <select
                  name="budget"
                  id="budget"
                  value={userData.budget}
                  onChange={postUserData}
                  required
                >
                  <option value="">Select<span style={{ color: "red" }}>*</span></option>
                  <option value="10K-20K">₹10,000 - ₹20,000</option>
                  <option value="20K-50K">₹20,000 - ₹50,0000</option>
                  <option value="50K-1L">₹50,000 - ₹1,00000</option>
                  <option value="Above 1L">Above ₹1,00000</option>
                </select>
              </div>
            </div>
            <div className="input_row textarea_row">
              <div className="input_item textarea_item">
                <label htmlFor="Details">Additional Details<span style={{ color: "red" }}>*</span></label>
                <textarea
                  name="details"
                  rows={5}
                  cols={0}
                  value={userData.details}
                  onChange={postUserData}
                ></textarea>
              </div>
            </div>
            {emptyFieldError && (
              <p className="error">Please fill all the required fields</p>
            )}
            <div className="input_row flex justify-center">
              <button type="submit" onClick={submitData}>
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      {showPopup && (
        <ThankYouPopup
          message="Thank you for submitting project details! We'll get back to you for further discussions. Stay Tuned"
          onClose={() => setShowPopup(false)}
        />
      )}
    </>
  );
};

export default Project_Discussion;
