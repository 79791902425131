import React from "react";
import "../assets/css/Popup.css";
import { IoCloseCircleSharp } from "react-icons/io5";
import { IoCheckmarkDoneCircle } from "react-icons/io5";

const ThankYouPopup = ({ message, onClose }) => {
  return (
    <>
      <div className="popup_overlay">
        <div className="popup_content">
            <div className="flex flex-center justify-center mb-10">
                <IoCheckmarkDoneCircle className="check_icon" />
            </div>
          <p>{message}</p>
          <button className="close_btn" onClick={onClose}>
            <IoCloseCircleSharp className="close_icon"/>
          </button>
        </div>
      </div>
    </>
  );
};

export default ThankYouPopup;
