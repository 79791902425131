import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0px;
        padding: 0px;
        box-sizing: border-box;
    }

    a {
        text-decoration: none;
    }

    li {
        list-style: none;
    }

    
    .d-none {
        display: none;
    }

    .flex {
        display: flex;
    }

    .flex-center {
        align-items: center;
    }

    .flex-end {
        align-items: flex-end;
    }

    .justify-center {
        justify-content: center;
    }

    .justify-end {
        justify-content: flex-end;
    }

    .space-between {
        justify-content: space-between;
    }

    .full-width {
        width: 100%;
    }

    .gap_50 {
        gap: 50px;
    }

    .mb-10 {
        margin-bottom: 10px;
    }
`;
export default GlobalStyle;