import React from "react";
import "../assets/css/Header.css";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("contact");
  };

  const handleNavbar = () => {
    const navbar = document.querySelector(".header_wrapper");
    const navList = document.querySelector(".nav_list");
    const logo = document.querySelector(".logo");
    navList.classList.toggle("v-class");
    logo.classList.toggle("v-class");
    navbar.classList.toggle("h-nav");
  };

  const closeNavbar = () => {
    const navbar = document.querySelector(".header_wrapper");
    const navList = document.querySelector(".nav_list");
    const logo = document.querySelector(".logo");
    navList.classList.add("v-class"); // Ensures navbar closes
    logo.classList.add("v-class"); // Ensures logo disappears on mobile
    navbar.classList.add("h-nav"); // Ensures navbar hides on mobile
  };
  return (
    <>
      <div className="header_wrapper h-nav">
        <div className="logo v-class">
          <Link to="/">
            <img src={logo} alt="logo couldn't be loaded" />
          </Link>
        </div>
        <div>
          <ul className="nav_list v-class">
            <li>
              <NavLink to="https://github.com/AnkitLakhlan" target="_blank">
                Github
              </NavLink>
            </li>
            <li>
              <a
                href="/ankit_resume.pdf" // The path to the resume file in the public folder
                download="Ankit_Lakhlan_Resume.pdf" // Optional: The filename for the download
                onClick={closeNavbar} // Close navbar on click (for mobile)
              >
                Resume{" "}
              </a>
            </li>
            <li>
              <button
                onClick={() => {
                  handleRedirect();
                  closeNavbar();
                }}
              >
                Say Hello
              </button>
            </li>
          </ul>
        </div>
        <div className="burger" onClick={handleNavbar}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
    </>
  );
};

export default Header;
