import React from "react";
import "../assets/css/Footer.css";
import logo from "../assets/images/logo_without_bg.png";
import { FaRegCopyright } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer_wrapper">
          <div className="footer_content">
            <div className="top_footer">
              <div className="logo">
                <img src={logo} alt="" />
              </div>
              <p>
                Living, learning, & leveling up <br /> one at a time
              </p>
            </div>
            <div className="socials">
              <Link to="https://www.linkedin.com/in/ankit-lakhlan-6098b820a" target="_blank">
                <div className="social_icon_container">
                  <FaLinkedin className="social_icon" />
                </div>
              </Link>
              <Link to="https://github.com/AnkitLakhlan" target="_blank">
                <div className="social_icon_container">
                  <FaGithub className="social_icon" />
                </div>
              </Link>
              <Link to="mailto:ankitlakhan03@gmail.com" target="_blank">
                <div className="social_icon_container">
                  <MdEmail className="social_icon" />
                </div>
              </Link>
            </div>
            <div className="copyright">
                <p>Made from scratch by Ankit Lakhlan | All rights reserved  <FaRegCopyright className="copyright_icon"/></p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
